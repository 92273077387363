import React, { useEffect } from 'react';
import { CalendarViewModel } from '../ViewModel/viewModel';
import { CalendarActions } from '../Actions/actions';
import { WidgetProps, useEnvironment } from '@wix/yoshi-flow-editor';
import { CalendarStatus } from '../ViewModel/widgetViewModel/widgetViewModel';
import { useSettings } from '@wix/tpa-settings/react';
import { useWidgetAccessibilityAttributes } from '../Hooks/useWidgetAccessibilityAttributes/useWidgetAccessibilityAttributes';
import { CalendarActionsProvider } from '../Hooks/useCalendarActions';
import { CalendarFlowProvider, FlowElements, useFlow } from '../Hooks/useFlow';
import { useSettingsParams } from '../Hooks/useSettingsParams';
import { PresetProvider, usePreset } from '../Hooks/usePreset';
import Header from './Header/Header';
import RescheduledBookingDetails from './RescheduledBookingDetails/RescheduledBookingDetails';
import Dialog from './Dialog/Dialog';
import Toast from './Toast/Toast';
import Sidebar from './Sidebar/Sidebar';
import { BackButton } from './BackButton/BackButton';
import DailyAgendaWeeklyPickerLayout from './DailyLayout/DailyAgendaWeeklyPickerLayout/DailyAgendaWeeklyPickerLayout';
import WeeklyTimeSlotsLayout from './WeeklyLayout/WeeklyTimeSlotsLayout/WeeklyTimeSlotsLayout';
import WeeklyTimetableLayout from './WeeklyLayout/WeeklyTimetableLayout/WeeklyTimetableLayout';
import DailyTimeSlotsLayout from './DailyLayout/DailyTimeSlotsLayout/DailyTimeSlotsLayout';
import MonthlyDatePicker from './DailyLayout/DatePicker/MonthlyDatePicker/MonthlyDatePicker';
import WeeklyDatePicker from './DailyLayout/DatePicker/WeeklyDatePicker/WeeklyDatePicker';
import EmptyState from './EmptyState/EmptyState';
import { classes, st } from './Widget.st.css';
import { WidgetLoader } from './WidgetLoader/WidgetLoader';
import { useWidgetLoaderHeight } from './WidgetLoader/useWidgetLoaderHeight/useWidgetLoaderHeight';
import { withSlotsPlaceholders } from '@wix/widget-plugins-ooi';

export interface ControllerProps extends CalendarActions, CalendarViewModel {}

const WidgetWrapper: React.FC<widgetprops<controllerprops>> = (props) => {
  const { widgetViewModel } = props;

  return widgetViewModel?.emptyStateViewModel ? (
    <>
      <backbutton viewModel="{widgetViewModel.backButtonViewModel}"></backbutton>
      <emptystate {...widgetViewModel?.emptyStateViewModel}=""></emptystate>
    </>
  ) : (
    <calendarflowprovider value="{widgetViewModel.focusedElement}">
      <presetprovider value="{widgetViewModel.preset}">
        <calendaractionsprovider value="{props}">
          <widget {...props}=""></widget>
        </calendaractionsprovider>
      </presetprovider>
    </calendarflowprovider>
  );
};

const Widget: React.FC<widgetprops<controllerprops>> = (props) => {
  const {
    headerViewModel,
    rescheduleDetailsViewModel,
    widgetViewModel: { backButtonViewModel, status },
    initializeWidget,
    toastViewModel,
    dialogViewModel,
    sidebarViewModel,
  } = props;
  const accessibilityAttributes = useWidgetAccessibilityAttributes(
    headerViewModel?.title,
  );
  const { preset } = usePreset();
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const { isMobile, isRTL } = useEnvironment();
  const { loaderHeight, shouldUseAsPlaceholder } = useWidgetLoaderHeight();

  useEffect(() => {
    if (status === CalendarStatus.INITIALIZING) {
      initializeWidget();
    }
  }, [initializeWidget, status]);

  const getWidgetLoader = () => <widgetloader minHeight="{loaderHeight}"></widgetloader>;

  const getWidgetContentByStatus = () => {
    switch (status) {
      case CalendarStatus.INITIALIZING:
        return getWidgetLoader();
      case CalendarStatus.IDLE:
      case CalendarStatus.NAVIGATING:
        return (
          <bottomsectioncontent {...props}="" minHeight="{shouldUseAsPlaceholder" ?="" loaderHeight="" :="" undefined}=""></bottomsectioncontent>
        );
      predefinito:
        return getWidgetLoader();
    }
  };

  return (
    <div className="{st(classes.root," {="" isRTL,="" isMobile,="" textAlignment:="" settings.get(settingsParams.textAlignment),="" columnAlignment:="" settings.get(settingsParams.columnAlignment),="" layout:="" settings.get(settingsParams.calendarLayout),="" showTopSection:="" !!headerViewModel="" ||="" !!rescheduleDetailsViewModel,="" isNavigating:="" status="==" CalendarStatus.NAVIGATING,="" preset,="" isDateAndTimeViewMode:="" !!sidebarViewModel?.summaryViewModel,="" })}="" dir="{isRTL" ?="" 'rtl'="" :="" 'ltr'}="" data-hook="BookingCalendar-wrapper" {...accessibilityAttributes}="">
      <div data-hook="top-section" className="{classes.topSection}">
        <backbutton viewModel="{backButtonViewModel}"></backbutton>
        <div className="{classes.topSectionHeader}">
          <div data-hook="top-section-content" className="{classes.topSectionContentContainer}">
            <div data-hook="reschedule-details-wrapper">
              {rescheduleDetailsViewModel && (
                <rescheduledbookingdetails key="rescheduledBookingDetails" viewModel="{rescheduleDetailsViewModel}"></rescheduledbookingdetails>
              )}
            </div>
            {headerViewModel ? <header viewModel="{headerViewModel!}"></header> : null}
          </div>
        </div>
      </div>
      <div data-hook="bottom-section" className="{classes.bottomSection}">
        <div data-hook="bottom-section-content" className="{classes.bottomSectionContentContainer}">
          {getWidgetContentByStatus()}
        </div>
      </div>
      {dialogViewModel && <dialog viewModel="{dialogViewModel}"></dialog>}
      {toastViewModel && <toast viewModel="{toastViewModel}"></toast>}
    </div>
  );
};

const BottomSectionContent: React.FC<
  ControllerProps & { minHeight?: number }
> = (props) => {
  const bottomSectionRef = useFlow(FlowElements.BOTTOM_SECTION);
  const { sidebarViewModel } = props;
  const minHeight = props.minHeight;

  return (
    <div className="{classes.bottomSectionContent}" style="{{" minHeight="" }}="" ref="{bottomSectionRef}">
      <body {...props}=""></body>
      {sidebarViewModel ? <sidebar viewModel="{sidebarViewModel}"></sidebar> : null}
    </div>
  );
};

const Body: React.FC<controllerprops> = ({
  widgetViewModel: { errorNotificationText },
  bodyViewModel,
}) => {
  const {
    weeklyTimeSlotsLayoutViewModel,
    dailyTimeSlotsMonthlyPickerLayoutViewModel,
    dailyTimeSlotsWeeklyPickerLayoutViewModel,
    weeklyTimetableLayoutViewModel,
    dailyAgendaWeeklyPickerLayoutViewModel,
  } = bodyViewModel || {};

  return (
    <div className="{classes.body}" data-hook="body">
      {dailyTimeSlotsMonthlyPickerLayoutViewModel ? (
        <dailytimeslotslayout viewModel="{dailyTimeSlotsMonthlyPickerLayoutViewModel}" errorNotificationText="{errorNotificationText}" datePickerComponent="{" <MonthlyDatePicker="" dailyTimeSlotsMonthlyPickerLayoutViewModel.datePickerViewModel="" }=""></dailytimeslotslayout>
          }
        />
      ) : null}
      {dailyTimeSlotsWeeklyPickerLayoutViewModel ? (
        <dailytimeslotslayout viewModel="{dailyTimeSlotsWeeklyPickerLayoutViewModel}" errorNotificationText="{errorNotificationText}" datePickerComponent="{" <WeeklyDatePicker="" dailyTimeSlotsWeeklyPickerLayoutViewModel.datePickerViewModel="" }=""></dailytimeslotslayout>
          }
        />
      ) : null}
      {weeklyTimeSlotsLayoutViewModel ? (
        <weeklytimeslotslayout viewModel="{weeklyTimeSlotsLayoutViewModel}" errorNotificationText="{errorNotificationText}"></weeklytimeslotslayout>
      ) : null}
      {weeklyTimetableLayoutViewModel ? (
        <weeklytimetablelayout viewModel="{weeklyTimetableLayoutViewModel}" errorNotificationText="{errorNotificationText}"></weeklytimetablelayout>
      ) : null}
      {dailyAgendaWeeklyPickerLayoutViewModel ? (
        <dailyagendaweeklypickerlayout viewModel="{dailyAgendaWeeklyPickerLayoutViewModel}" errorNotificationText="{errorNotificationText}"></dailyagendaweeklypickerlayout>
      ) : null}
    </div>
  );
};

esportazione predefinita withSlotsPlaceholders(WidgetWrapper);
</controllerprops></widgetprops<controllerprops></widgetprops<controllerprops>